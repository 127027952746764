<template>
  <div class="land">
    <div class="land-admin">
      <div class="adTitle">土地管理</div>
      <div class="ad-bot">
        <div class="left-cont"></div>
        <div class="r-add" @click="addLands">
          <img src="../../assets/image/land/addland.png" class="add-icons" />
          <p class="addName">新建地块</p>
        </div>
      </div>
    </div>
    <div class="mains">
      <div class="land-lists" v-if="landList.length">
        <div
          class="items wow animated fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
          data-wow-offset="50"
          v-for="(item, index) in landList"
          :key="item.id"
        >
          <div class="col1">
            <img
              :src="
                item.photo
                  ? uploadImgs + item.photo
                  : require('../../assets/image/land/bgc.png')
              "
              class="bg-imgs"
            />
            <div class="tags">{{ item.title }}</div>
            <div class="icon-btn">
              <img
                class="edits"
                src="../../assets/image/land/edits.png"
                @click="editLand(item.id)"
              />
              <img
                class="close"
                src="../../assets/image/land/close.png"
                v-show="!item.custom_state"
                @click="enableCrops(item.id, index)"
              />
              <img
                class="open"
                src="../../assets/image/land/open.png"
                v-show="item.custom_state"
                @click="disableCrops(item.id, index)"
              />
              <img
                src="../../assets/image/traceability/icon12.png"
                @click="delLand(item.id, index)"
              />
            </div>
          </div>
          <div class="col2">
            <div></div>
            <p class="one-txt-cut">
              面积：{{ (item.area * 0.0015).toFixed(0) }}亩
            </p>
          </div>
          <div class="col2">
            <div></div>
            <p class="one-txt-cut">
              产品：
              <span v-for="(t, i) in item.product_list" :key="i"
                >{{ t.title }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="land-lists" v-else>
        <MyNothing></MyNothing>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
        v-if="landList.length"
      >
      </el-pagination>
      <MyNav></MyNav>
    </div>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import {
  getPlotListApi,
  postEditPlotStateApi,
  delPlotApi,
} from "@/request/api";
import { mapState } from "vuex";

export default {
  components: {
    MyNothing: () => import("@/components/MyNothing.vue"),
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {
      landList: [],
      id: "",
      land_id: "",
      total: 20, 
      per_page: 10, 
      current_page: 1, 
      title: undefined,
      custom_state: 1,
    };
  },
  watch: {
    current_page(val) {
      this.getLists(val);
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    this.getLists();
  },
  methods: {
    async delPlotApi() {
      const { code } = await delPlotApi({
        plot_ids: [this.land_id],
      });
      if (code === 0) {
        this.$notify({
          title: "成功",
          message: "已删除!",
          type: "success",
        });
      }
    },
    delLand(id, index) {
      this.land_id = id;
      this.$confirm("此操作将删除该地块, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delPlotApi();
          this.landList.splice(index, 1);
          if (!this.landList.length) {
            this.current_page = 1;
          }
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    async postEditPlotStateApi(index, status) {
      const { code } = await postEditPlotStateApi({
        plot_id: this.id,
        custom_state: status,
      });
      if (code === 0) {
        this.landList[index].custom_state = !this.landList[index].custom_state;
        this.$forceUpdate();
      }
    },
    async getLists() {
      showFullScreenLoading();
      const { code, results } = await getPlotListApi({
        manor_id: this.farmID,
        pn: this.current_page,
        title: this.title,
        custom_state: this.custom_state,
      });
      if (code === 0) {
        this.total = results.count;
        this.per_page = results.ps;
        this.current_page = results.pn;
        this.landList = results.data;
      }
      tryHideFullScreenLoading();
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getLists();
    },
    enableCrops(id, index) {
      this.id = id;
      this.postEditPlotStateApi(index, 1);

      this.$notify({
        title: "成功",
        message: "已启用",
        type: "success",
      });
    },
    disableCrops(id, index) {
      this.id = id;
      this.$confirm("此操作将禁用该地块, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.postEditPlotStateApi(index, 0);
          this.$notify({
            title: "成功",
            message: "已禁用",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    editLand(id) {
      this.$router.push({
        name: "alter",
        query: { type: id },
      });
    },
    addLands() {
      this.$router.push({
        name: "alter",
      });
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes typing {
  from {
    width: 0;
  }
}
@keyframes blink-caret {
  50% {
    border-color: transparent;
  }
}

.land {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 62px 48px 100px;
  .land-admin {
    width: 100%;
    height: 128px;
    background-color: #0e2138;
    padding: 19px 49px;
    margin: 16px 0;
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    .adTitle {
      width: 100%;
      line-height: 22px;
      height: 22px;
      font-size: 22px;
      font-weight: bold;
      color: #fff;
      padding-left: 11px;
      border-left: 4px solid #3e90e5;
      border-radius: 2px;
    }
    .r-add {
      width: 143px;
      height: 40px;
      background-color: #3e90e5;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
      .add-icons {
        width: 18px;
        height: 18px;
        margin-right: 13px;
      }
    }
  }
  .mains {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #0e2138;
    margin-bottom: 38px;
    padding: 26px 49px 22px;

    .land-lists {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .items {
        margin: 0 47px 34px 0;
        width: 307px;
        height: 299px;
        background: #081c30;
        border: 1px solid #385576;
        padding: 13px 16px 0;
        display: flex;
        flex-direction: column;

        .col1 {
          width: 100%;
          height: 164px;
          margin-bottom: 15px;
          position: relative;

          .bg-imgs {
            width: 100%;
            height: 100%;
          }

          .tags {
            min-width: 108px;
            text-align: center;
            height: 30px;
            line-height: 30px;
            padding: 0 8px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            position: absolute;
            left: 0;
            top: 12px;
            color: #fff;
            font-weight: Bold;
            font-size: 16px;
            background-color: #6200bd;
          }

          .icon-btn {
            display: flex;
            align-items: center;
            display: none;
            position: absolute;
            right: 13px;
            top: 12px;

            img {
              width: 33px;
              height: 32px;
              cursor: pointer;
              margin-right: 9px;

              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }

        .col2 {
          width: 100%;
          height: 28px;
          background: #0e2f53;
          border-radius: 14px;
          display: flex;
          align-items: center;
          padding: 0 14px;
          margin-bottom: 13px;

          > div {
            width: 5px;
            height: 5px;
            background: #a46dd7;
            border-radius: 50%;
            margin-right: 11px;
          }

          p {
            width: calc(100% -- 5px);
            color: #fff;
            font-size: 14px;
            margin: 0;
          }
        }

        &:nth-of-type(5n) {
          margin-right: 0;
        }

        :hover {
          .icon-btn {
            display: block;
          }
        }
      }
    }
  }

  /deep/.el-notification {
    z-index: 9999;
  }
}
</style>
